import React, { useEffect, useMemo, useState, useContext } from "react";
import PropTypes from "prop-types";
import * as Yup from "yup";
import Paper from "@mui/material/Paper";
import SaveIcon from "@mui/icons-material/Save";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Formik, Form } from "formik";

import { SnackbarContext } from "../../context/SnackbarContext";
import LoadingBackdrop from "../LoadingBackdrop/LoadingBackdrop";
import { useResourceAsync, Operations } from "../../hooks/useResourceAsync";
import lc from "./localeContent";
import commonLocaleContent from "../../screens/commonLocaleContent";
import { objectMap } from "../../utils/objectUtils";
import FormTextField from "../FormTextField/FormTextField";
import FormField from "../CrudForm/FormField";
import { MUI_GRID_CONTAINER_SPACING } from "../../config/constants";

const dialogTitleId = "segment-file-dialog-title";
const validationSchema = Yup.object().shape({
	name: Yup.string()
		.required(commonLocaleContent.REQUIRED_FIELD_WARNING)
		.max(250, "Segment name must be less than 250 characters")
});

function SegmentResourceModal({
	initialFormValues,
	dataDistributionId,
	onFormSubmitSuccess
}) {
	const { triggerNewSnackbarMessage } = useContext(SnackbarContext);
	const isCreate = useMemo(() => !initialFormValues?.id, [initialFormValues]);

	const formSubmitConfig = useMemo(() => {
		const endpoint = "manage/segments";
		const operation = isCreate ? Operations.CREATE : Operations.UPDATE;
		const params = isCreate ? undefined : { resourceId: initialFormValues?.id };
		return { endpoint, operation, params };
	}, [isCreate, initialFormValues]);

	const {
		isLoading: isFormDataSubmitting,
		execute: submitFormData
	} = useResourceAsync(
		formSubmitConfig.endpoint,
		formSubmitConfig.operation,
		formSubmitConfig.params
	);

	const [isFormModalOpen, setIsFormModalOpen] = useState(false);

	// Open the modal anytime we set the initial form values
	useEffect(() => {
		if (initialFormValues) {
			setIsFormModalOpen(true);
		}
	}, [initialFormValues]);

	const closeModal = (event, reason) => {
		if (reason === "backdropClick") {
			return;
		}
		setIsFormModalOpen(false);
	};

	return (
		<Paper>
			<LoadingBackdrop isOpen={isFormDataSubmitting} />
			<Dialog
				open={isFormModalOpen}
				onClose={closeModal}
				aria-labelledby={dialogTitleId}
				fullWidth
				maxWidth="sm"
				closeAfterTransition={false} // Per https://github.com/mui/material-ui/issues/43106#issuecomment-2404007826
			>
				<Formik
					enableReinitialize
					initialValues={initialFormValues}
					validationSchema={validationSchema}
					onSubmit={async (formValues, formikActions) => {
						const { setErrors } = formikActions;
						// Add the data distribution ID to all requests
						const submitData = { ...formValues, dataDistributionId };
						const { data, error } = await submitFormData({ data: submitData });
						if (error) {
							const { detail, message } = error;
							// Field level errors
							if (detail) {
								// Join the array of errors into a single string
								setErrors(objectMap(detail, value => value.join(" - ")));
							}
							// Form level errors
							else if (message) {
								triggerNewSnackbarMessage({ message, severity: "error" });
							}
						}
						if (data) {
							onFormSubmitSuccess();
							triggerNewSnackbarMessage({
								message: lc.SAVE_SUCCESS_MESSAGE(data.name),
								severity: "success"
							});
							closeModal();
						}
					}}
				>
					<Paper>
						<Form noValidate>
							<DialogTitle id={dialogTitleId}>
								{isCreate
									? lc.ADD_SEGMENT_MODAL_LABEL
									: lc.EDIT_SEGMENT_MODAL_LABEL}
								<IconButton
									aria-label={commonLocaleContent.CLOSE}
									onClick={closeModal}
									sx={theme => ({
										position: "absolute",
										right: theme.spacing(1),
										top: theme.spacing(1)
									})}
								>
									<CloseIcon />
								</IconButton>
							</DialogTitle>
							<DialogContent>
								<Grid container spacing={MUI_GRID_CONTAINER_SPACING}>
									<Grid item xs={12}>
										<FormField>
											<FormTextField
												name="name"
												id="name"
												label={lc.FIELD.SEGMENT_NAME.LABEL}
												required
											/>
										</FormField>
									</Grid>
								</Grid>
							</DialogContent>
							<DialogActions>
								<Button onClick={closeModal}>
									{commonLocaleContent.CANCEL}
								</Button>
								<Button
									color="primary"
									variant="contained"
									type="submit"
									startIcon={<SaveIcon />}
								>
									{commonLocaleContent.SAVE}
								</Button>
							</DialogActions>
						</Form>
					</Paper>
				</Formik>
			</Dialog>
		</Paper>
	);
}

SegmentResourceModal.propTypes = {
	initialFormValues: PropTypes.shape(),
	dataDistributionId: PropTypes.string.isRequired,
	onFormSubmitSuccess: PropTypes.func.isRequired
};

SegmentResourceModal.defaultProps = {
	initialFormValues: null
};

export default SegmentResourceModal;
