import React from "react";
import PropTypes from "prop-types";
import { Box, Typography } from "@mui/material";
import {
	formatAsCurrency,
	formatAsNumberWithSeparator,
	formatAsPercent
} from "../../../utils/formatters";
import { METRIC_TYPES } from "../metricsConfig";
import { PRIMARY_COLORS } from "../../../config/constants";

const formattersByType = {
	[METRIC_TYPES.NUMBER]: value => formatAsNumberWithSeparator(value),
	[METRIC_TYPES.CURRENCY]: value => `$${formatAsCurrency(value)}`,
	[METRIC_TYPES.PERCENT]: value => formatAsPercent(value)
};

// NOTE: 12/9/2024 - Unable to trigger the tooltip using jest after multiple attempts, so using cypress to test the tooltip
function CustomTooltip({ active, payload, label: xAxisLabel, external }) {
	const { primarySeries, tooltipConfig } = external;
	const getDateColumnLabel = tooltipConfig.getDateColumnLabel || (() => "");
	const getPrimaryRowLabel =
		tooltipConfig.getPrimaryRowLabel || (() => xAxisLabel);
	const getComparisonRowLabel =
		tooltipConfig.getComparisonRowLabel || (() => "");

	if (active) {
		const isComparisonData = payload.length > primarySeries.length;

		return (
			<Box
				sx={{
					bgcolor: "white",
					border: "1px solid #ccc",
					borderRadius: 4,
					px: 2,
					boxShadow: 1
				}}
			>
				<table
					style={{
						borderCollapse: "separate",
						borderSpacing: "0 8px",
						margin: 0,
						padding: 0
					}}
				>
					<thead>
						<tr>
							{/* Add a spacer cell */}
							<th>{getDateColumnLabel(xAxisLabel, isComparisonData)}</th>
							{/* Add header cell for each series */}
							{primarySeries.map((series, index) => (
								<th
									key={series.metric}
									scope="col"
									style={{
										color: payload[index].color
									}}
									align="right"
								>
									<Typography sx={{ pl: 3, fontWeight: "bold" }}>
										{series.label}
									</Typography>
								</th>
							))}
						</tr>
					</thead>
					<tbody>
						{/* Add main table row */}
						<tr>
							<th scope="row">
								{getPrimaryRowLabel(xAxisLabel, isComparisonData)}
							</th>
							{primarySeries.map((series, index) => {
								const { value } = payload[index];
								return (
									<td key={series.metric} align="right">
										<Typography>
											{formattersByType[series.type](value)}
										</Typography>
									</td>
								);
							})}
						</tr>
						{/* Add comparison data rows */}
						{isComparisonData && (
							<>
								<tr>
									<th scope="row">{getComparisonRowLabel(xAxisLabel)}</th>
									{primarySeries.map((series, index) => {
										const { value } = payload[index + primarySeries.length];
										return (
											<td key={series.metric} align="right">
												<Typography>
													{formattersByType[series.type](value)}
												</Typography>
											</td>
										);
									})}
								</tr>
								{/* Add comparison percents */}
								<tr>
									{/* Spacer cell */}
									<td />
									{primarySeries.map((series, index) => {
										const { value } = payload[index];
										const comparisonValue =
											payload[index + primarySeries.length].value;
										const comparisonPercent =
											comparisonValue === 0
												? 0
												: (value - comparisonValue) / comparisonValue;
										let color;
										let operator;
										if (comparisonPercent > 0) {
											color = PRIMARY_COLORS.GREEN.id;
											operator = "+";
										}
										if (comparisonPercent < 0) {
											color = PRIMARY_COLORS.RED.id;
											operator = "-";
										}
										return (
											<td
												key={series.metric}
												align="right"
												style={{
													color
												}}
											>
												<Typography>
													{operator}
													{formatAsPercent(comparisonPercent)}
												</Typography>
											</td>
										);
									})}
								</tr>
							</>
						)}
					</tbody>
				</table>
			</Box>
		);
	}
	return null;
}

CustomTooltip.propTypes = {
	active: PropTypes.bool,
	payload: PropTypes.arrayOf(PropTypes.shape()),
	label: PropTypes.string,
	external: PropTypes.shape()
};
CustomTooltip.defaultProps = {
	active: false,
	label: "",
	payload: [],
	external: {}
};

export default CustomTooltip;
