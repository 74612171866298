import React, { useEffect, useMemo, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import BaseDataGrid from "../BaseDataGrid/BaseDataGrid";
import LoadingBackdrop from "../LoadingBackdrop/LoadingBackdrop";
import { useResourceAsync, Operations } from "../../hooks/useResourceAsync";

import lc from "./localeContent";
import SegmentFileUpload from "./SegmentFileUpload";
import SegmentResourceModal from "./SegmentResourceModal";

function SegmentTable({ dataDistributionId }) {
	const [fileUploadSegmentId, setFileUploadSegmentId] = useState("");
	const [initialSegmentFormValues, setInitialSegmentFormValues] = useState(
		null
	);

	const dataDistributionEndpoint = useMemo(
		() => `manage/dataDistributions/${dataDistributionId}/segments`,
		[dataDistributionId]
	);
	// TODO: Implement error handling
	const {
		data: indexData,
		isLoading: isIndexLoading,
		execute: requestIndexData
	} = useResourceAsync(dataDistributionEndpoint, Operations.LIST);

	// Fetch the data when the data distribution ID changes. Practically speaking, this should only happen once when the form loads
	useEffect(() => {
		if (dataDistributionId) {
			requestIndexData();
		}
	}, [dataDistributionId, requestIndexData]);

	const openSegmentAddForm = () => {
		setInitialSegmentFormValues({ name: "" });
	};

	const openFileUploadForm = id => {
		setFileUploadSegmentId(id);
	};

	const columns = useMemo(() => {
		return [
			{ field: "name", headerName: "Segment Name", flex: 1 },
			{
				field: "upload",
				headerName: "Upload",
				renderCell: ({ row }) => (
					<Button
						color="primary"
						startIcon={<FileUploadIcon />}
						onClick={() => openFileUploadForm(row.id)}
						style={{ minWidth: "auto", padding: 1 }}
						aria-label={lc.FILE_UPLOAD_ARIA_LABEL}
					/>
				),
				sortable: false,
				filterable: false,
				flex: 0
			}
		];
	}, []);

	return (
		<Paper>
			<LoadingBackdrop isOpen={isIndexLoading} />
			<BaseDataGrid
				rows={indexData}
				columns={columns}
				paginationConfig={{ defaultRowsPerPage: 10 }}
				defaultSortConfig={{ field: "name", direction: "asc" }}
				gridHeight={450}
				disableColumnSelector
				disableDensitySelector
				tableActionButton={
					<Button
						variant="contained"
						onClick={openSegmentAddForm}
						type="button"
						color="secondary"
						startIcon={<AddIcon />}
					>
						{lc.ADD_SEGMENT_BUTTON_LABEL}
					</Button>
				}
				gridAriaLabel={lc.GRID_ARIA_LABEL}
			/>
			<SegmentResourceModal
				initialFormValues={initialSegmentFormValues}
				dataDistributionId={dataDistributionId}
				onFormSubmitSuccess={() => requestIndexData()}
			/>
			<SegmentFileUpload
				setSegmentId={setFileUploadSegmentId}
				segmentId={fileUploadSegmentId}
			/>
		</Paper>
	);
}

SegmentTable.propTypes = {
	dataDistributionId: PropTypes.string.isRequired
};

export default SegmentTable;
