import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { subDays, format } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";

import BasicLineChart from "./BasicLineChart";
import hourLabels from "./hourLabels";
import { UTC_TIME_ZONE } from "../../../config/constants";

const getSeriesPointLabel = index => hourLabels[index];
const TICKS = [hourLabels[3], hourLabels[9], hourLabels[15], hourLabels[21]];

function HourlyChart(props) {
	const {
		metricsData,
		comparisonData,
		isLoadingMetricsData,
		reportType,
		series,
		startDateTimestamp
	} = props;

	const startDate = utcToZonedTime(startDateTimestamp, UTC_TIME_ZONE);
	const comparisonDate = subDays(startDate, 1);

	const customTooltipConfig = useMemo(
		() => ({
			getDateColumnLabel: (xAxisLabel, isComparisonData) =>
				isComparisonData ? xAxisLabel : "",
			getPrimaryRowLabel: (xAxisLabel, isComparisonData) =>
				isComparisonData ? format(startDate, "MM/dd") : xAxisLabel,
			getComparisonRowLabel: () => format(comparisonDate, "MM/dd")
		}),
		[startDate, comparisonDate]
	);

	return (
		<BasicLineChart
			metricsData={metricsData}
			comparisonData={comparisonData}
			isLoadingMetricsData={isLoadingMetricsData}
			ticks={TICKS}
			getSeriesPointLabel={getSeriesPointLabel}
			customTooltipConfig={customTooltipConfig}
			reportType={reportType}
			seriesDefaultData={series}
		/>
	);
}

HourlyChart.propTypes = {
	metricsData: PropTypes.arrayOf(PropTypes.shape()),
	comparisonData: PropTypes.arrayOf(PropTypes.shape()),
	isLoadingMetricsData: PropTypes.bool.isRequired,
	reportType: PropTypes.string.isRequired,
	series: PropTypes.arrayOf(PropTypes.shape()).isRequired,
	startDateTimestamp: PropTypes.number
};

HourlyChart.defaultProps = {
	startDateTimestamp: null,
	metricsData: null,
	comparisonData: null
};

export default HourlyChart;
