import React, { useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import { addDays, subDays, format, parse } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";

import BasicLineChart from "./BasicLineChart";
import { UTC_TIME_ZONE } from "../../../config/constants";

function DailyChart(props) {
	const {
		metricsData,
		comparisonData,
		isLoadingMetricsData,
		startDateTimestamp,
		reportType,
		series,
		comparisonDayDifference
	} = props;

	const startDate = utcToZonedTime(startDateTimestamp, UTC_TIME_ZONE);

	const getSeriesPointLabel = useCallback(
		index => format(addDays(startDate, index), "MM/dd"),
		[startDate]
	);

	const customTooltipConfig = useMemo(() => {
		return {
			getPrimaryRowLabel: xAxisLabel => xAxisLabel,
			getComparisonRowLabel: xAxisLabel => {
				const primaryDate = parse(xAxisLabel, "MM/dd", new Date());
				const comparisonDate = subDays(primaryDate, comparisonDayDifference);
				return format(comparisonDate, "MM/dd");
			}
		};
	}, [comparisonDayDifference]);

	return (
		<BasicLineChart
			metricsData={metricsData}
			comparisonData={comparisonData}
			isLoadingMetricsData={isLoadingMetricsData}
			minTickGap={300}
			getSeriesPointLabel={getSeriesPointLabel}
			customTooltipConfig={customTooltipConfig}
			reportType={reportType}
			seriesDefaultData={series}
		/>
	);
}

DailyChart.propTypes = {
	startDateTimestamp: PropTypes.number,
	metricsData: PropTypes.arrayOf(PropTypes.shape()),
	comparisonData: PropTypes.arrayOf(PropTypes.shape()),
	isLoadingMetricsData: PropTypes.bool.isRequired,
	reportType: PropTypes.string.isRequired,
	series: PropTypes.arrayOf(PropTypes.shape()).isRequired,
	comparisonDayDifference: PropTypes.number
};

DailyChart.defaultProps = {
	startDateTimestamp: null,
	metricsData: null,
	comparisonData: null,
	comparisonDayDifference: 0
};

export default DailyChart;
