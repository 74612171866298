const CONDITIONAL_SCHEDULED_REPORTS_ITEMS = {
	city: { mustBeBefore: ["dma", "geo"] },
	day: { disabledWith: ["domain"] },
	dma: { mustBeAfter: ["city"], mustBeBefore: ["geo"] },
	domain: {
		disabledWith: ["day", "hour"]
	},
	geo: { mustBeAfter: ["dma", "city"] },
	hour: {
		disabledWith: ["domain"]
	},
	buyers: { mustBeAfter: ["seatId"] },
	dealid: { mustBeAfter: ["seatId"] },
	seatId: {
		mustBeBefore: ["buyers", "dealid"],
		enabledWith: ["buyers"]
	}
};
export default CONDITIONAL_SCHEDULED_REPORTS_ITEMS;

export const CONDITIONAL_REPORTING_ITEMS = {
	day: { mustBeAfter: ["hour"] },
	domain: {
		disabledWhenDateRange: {
			greaterThan: 7
		}
	},
	contentgenre: {
		disabledWhenDateRange: {
			greaterThan: 7
		}
	},
	contenttitle: {
		disabledWhenDateRange: {
			greaterThan: 7
		}
	},
	hour: {
		mustBeBefore: ["day"],
		disabledWhenDateRange: {
			greaterThanInPast: 31
		}
	},
	buyers: { mustBeAfter: ["seatId"] },
	dealid: { mustBeAfter: ["seatId"] },
	seatId: {
		mustBeBefore: ["buyers", "dealid"],
		enabledWith: ["buyers"]
	},
	appName: {
		disabledWhenDateRange: {
			greaterThan: 7
		}
	},
	appBundle: {
		disabledWhenDateRange: {
			greaterThan: 7
		}
	}
};
