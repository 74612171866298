import React from "react";
import PropTypes from "prop-types";
import { Box, FormControl, MenuItem, Select } from "@mui/material";
import { LEFT_METRIC_INDEX, RIGHT_METRIC_INDEX } from "../constants";
import Switch from "../../FormFieldSwitch/Switch";
import localeContent from "./localeContent";

function LegendDropDown(props) {
	const {
		metrics,
		color,
		selectedMetric,
		excludeMetric,
		onChange,
		ariaLabel
	} = props;
	return (
		<FormControl sx={{ mr: 1, minWidth: 150 }} size="small">
			<Select
				sx={{ color, fontWeight: 410 }}
				value={selectedMetric}
				onChange={onChange}
				SelectDisplayProps={{
					"aria-label": ariaLabel
				}}
				MenuProps={{
					style: {
						maxHeight: 310
					}
				}}
			>
				{metrics
					.filter(option => option.metric !== excludeMetric)
					.map(option => (
						<MenuItem value={option.metric} key={option.metric}>
							{option.label}
						</MenuItem>
					))}
			</Select>
		</FormControl>
	);
}

LegendDropDown.propTypes = {
	color: PropTypes.string.isRequired,
	selectedMetric: PropTypes.string.isRequired,
	excludeMetric: PropTypes.string,
	onChange: PropTypes.func.isRequired,
	metrics: PropTypes.arrayOf(PropTypes.shape()).isRequired,
	ariaLabel: PropTypes.string.isRequired
};

LegendDropDown.defaultProps = {
	excludeMetric: ""
};

function Legend(props) {
	const {
		metrics,
		leftSeriesColor,
		rightSeriesColor,
		leftMetric,
		rightMetric,
		onChange,
		showComparisonData,
		setShowComparisonData
	} = props;

	return (
		<Box
			sx={{
				mx: 8,
				mb: 1.5,
				display: "flex",
				alignItems: "center"
			}}
		>
			<LegendDropDown
				metrics={metrics}
				color={leftSeriesColor}
				selectedMetric={leftMetric}
				excludeMetric={rightMetric}
				onChange={e => onChange(e.target.value, LEFT_METRIC_INDEX)}
				ariaLabel={localeContent.LEFT_AXIS_METRIC_SELECT_LABEL}
			/>
			<LegendDropDown
				metrics={metrics}
				color={rightSeriesColor}
				selectedMetric={rightMetric}
				excludeMetric={leftMetric}
				onChange={e => onChange(e.target.value, RIGHT_METRIC_INDEX)}
				ariaLabel={localeContent.RIGHT_AXIS_METRIC_SELECT_LABEL}
			/>
			{setShowComparisonData && (
				<FormControl>
					<Switch
						id="showComparisonData"
						name="showComparisonData"
						label={localeContent.COMPARISON_DATA_SWITCH}
						value={showComparisonData}
						onChange={event => {
							setShowComparisonData(event.target.checked);
						}}
					/>
				</FormControl>
			)}

			<Box flexGrow={1} />
		</Box>
	);
}

Legend.propTypes = {
	leftSeriesColor: PropTypes.string.isRequired,
	rightSeriesColor: PropTypes.string.isRequired,
	leftMetric: PropTypes.string.isRequired,
	rightMetric: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired,
	metrics: PropTypes.arrayOf(PropTypes.shape()).isRequired,
	showComparisonData: PropTypes.bool,
	setShowComparisonData: PropTypes.func
};

Legend.defaultProps = {
	showComparisonData: false,
	setShowComparisonData: null
};

export default Legend;
