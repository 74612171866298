import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Alert } from "@mui/material";
import { format, subDays } from "date-fns";

import lc from "./localeContent";
import HourlyChart from "./HourlyChart";
import DailyChart from "./DailyChart";
import { DATE_RANGE_KEY, REPORT_TYPE_KEY, TIME_ZONE_KEY } from "../constants";
import { todayInTimezone } from "../../../utils/dateUtils";
import { dateRangeServerFormat } from "../../../config/constants";
import { Operations } from "../../../hooks/useResource";
import { buildPerformanceDataRequestParams } from "../reportingUtils";
import { useResourceAsync } from "../../../hooks/useResourceAsync";

const RESOLUTIONS = {
	HOUR: "HOUR"
};

// We use the daily druid index to speed up certain single day queries. When we only have one day to show, we hide the chart rather than graph a single point
const getUnchartablePerformanceDataWarning = activeReportConfig => {
	if (!activeReportConfig) {
		return null;
	}
	const {
		[DATE_RANGE_KEY]: dateRange,
		[TIME_ZONE_KEY]: timeZone,
		[REPORT_TYPE_KEY]: reportType
	} = activeReportConfig;

	// Return early if type is not RTB
	if (reportType !== "rtb") {
		return null;
	}

	const [startDate, endDate] = dateRange;
	const serverStartDate = format(startDate, dateRangeServerFormat);
	const serverEndDate = format(endDate, dateRangeServerFormat);

	const isSingleDayRange = serverStartDate === serverEndDate;
	// Only single day ranges are eligible for a chart warning
	if (!isSingleDayRange) {
		return null;
	}

	const todayInTimeZone = todayInTimezone(timeZone);

	// Check if date is more than 31 days in the past
	const isDateMoreThan31DaysOld = startDate < subDays(todayInTimeZone, 31);
	if (isDateMoreThan31DaysOld) {
		return "MORE_THAN_31_DAYS";
	}

	// UTC Dates within the past 31 days (other than today) have a warning
	const isStartDateToday =
		serverStartDate === format(todayInTimeZone, dateRangeServerFormat);
	if (timeZone === "Z" && !isStartDateToday) {
		return "WITHIN_31_DAYS";
	}

	return null;
};

function ReportingChart(props) {
	const {
		performanceData,
		comparisonData,
		isLoadingPerformanceData,
		series,
		activeReportConfig,
		comparisonDayDifference
	} = props;

	const [userOptedOutOfChartWarning, setUserOptedOutOfChartWarning] = useState(
		false
	);

	const unchartablePerformanceDataVariant = getUnchartablePerformanceDataWarning(
		activeReportConfig
	);

	// In some instances, performanceData does not include hourly data needed for the chart so we need to request it separately
	const shouldRequestOwnData =
		unchartablePerformanceDataVariant === "WITHIN_31_DAYS";

	const [shouldRequestData, setShouldRequestData] = useState(false);
	const {
		data: hourlyData,
		error: hourlyError,
		isLoading: isHourlyLoading,
		execute: requestHourlyData
	} = useResourceAsync("manage/metrics/performance", Operations.LIST);

	useEffect(() => {
		if (shouldRequestOwnData) {
			setShouldRequestData(true);
		}
	}, [shouldRequestOwnData, activeReportConfig]);

	if (shouldRequestData) {
		setShouldRequestData(false);
		requestHourlyData({
			requestParams: {
				...buildPerformanceDataRequestParams(activeReportConfig).requestParams,
				useHourlyOverride: true
			}
		});
	}

	// If we encounter any server errors when we should be requesting data, we should render an error alert
	if (hourlyError) {
		return (
			<Alert severity="error">{lc.CHART_WARNING.CHART_DATA_FETCH_ERROR}</Alert>
		);
	}

	// We can't chart single day ranges older than 31 days because of data retention policies, so we show a warning instead
	if (unchartablePerformanceDataVariant === "MORE_THAN_31_DAYS") {
		return (
			!userOptedOutOfChartWarning && (
				<Alert
					severity="info"
					onClose={() => {
						setUserOptedOutOfChartWarning(true);
					}}
				>
					{lc.CHART_WARNING[unchartablePerformanceDataVariant]}
				</Alert>
			)
		);
	}

	const { [REPORT_TYPE_KEY]: reportType } = activeReportConfig || {};

	const chartData = shouldRequestOwnData ? hourlyData : performanceData;
	const { resolution, startTimestamp, metrics = [] } = chartData || {};
	const { metrics: comparisonMetrics = [] } = comparisonData || {};

	const ChartComponent =
		resolution === RESOLUTIONS.HOUR ? HourlyChart : DailyChart;
	return (
		<ChartComponent
			metricsData={metrics}
			comparisonData={comparisonMetrics}
			isLoadingMetricsData={isLoadingPerformanceData || isHourlyLoading}
			startDateTimestamp={startTimestamp}
			reportType={reportType}
			series={series}
			comparisonDayDifference={comparisonDayDifference}
		/>
	);
}

ReportingChart.propTypes = {
	performanceData: PropTypes.shape(),
	comparisonData: PropTypes.shape(),
	isLoadingPerformanceData: PropTypes.bool,
	series: PropTypes.arrayOf(PropTypes.shape()).isRequired,
	activeReportConfig: PropTypes.shape().isRequired,
	comparisonDayDifference: PropTypes.number
};

ReportingChart.defaultProps = {
	performanceData: null,
	comparisonData: null,
	isLoadingPerformanceData: false,
	comparisonDayDifference: 0
};

export default ReportingChart;
